import React, { useEffect, useState } from 'react';
import logo from './img/LogoTechnoMagic.png';
import './App.scss';
import Gota from './components/gota/Gota';

const App: React.FC = () => {
  const [gotas, setGotas] = useState<number[]>([]);

  useEffect(() => {
    const generarGota = () => {
      const id = Date.now();
      setGotas(prev => [...prev, id]);

      // Eliminar gota después de su animación
      setTimeout(() => {
        setGotas(prev => prev.filter(gota => gota !== id));
      }, 2000); // 2 segundos es la duración de la animación (ajustar según el CSS)
    };

    const interval = setInterval(() => {
      generarGota();
    }, Math.random() * 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <div className="contenedor-imagen">
          {gotas.map(id => (
            <Gota key={id} />
          ))}
          <img src={logo} className="App-logo" alt="logo" />
        </div>
      </header>
    </div>
  );
}

export default App;
