import React from 'react';

interface GotaProps {}

const Gota: React.FC<GotaProps> = () => {
  const leftPosition = Math.random() * 100 + '%';
  const topPosition = Math.random() * 100 + '%';
  const animationDuration = `${Math.random() * 5 + 1}s`; // Genera un valor aleatorio entre 1 y 6 segundos

  return (
    <div 
      className="gota"
      style={{ 
        left: leftPosition, 
        top: topPosition,
        animationDuration: animationDuration  // Aplica la duración aleatoria a la animación
      }}
    ></div>
  );
}

export default Gota;
